var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container meeting-reservation" },
    [
      _c("div", { staticClass: "filter-line" }, [
        _c(
          "span",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.addNewHandler },
              },
              [_vm._v("新增配置")]
            ),
            _c("el-button", { on: { click: _vm.exportHandler } }, [
              _vm._v(_vm._s(this.$t("commons.export"))),
            ]),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-refresh-right" },
                on: {
                  click: function ($event) {
                    return _vm.getTableList()
                  },
                },
              },
              [_vm._v(_vm._s(this.$t("commons.refresh")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c("finalTable", {
            ref: "finalTableRef",
            attrs: { datas: _vm.dataset },
            on: { tableEventHandler: _vm.tableEventHandler },
            scopedSlots: _vm._u([
              {
                key: "projectIdSlot",
                fn: function (row) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(row.datas.projectName))]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.parkmeetingObj.dialogVisible,
            "before-close": _vm.handleClose,
            width: "900px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.parkmeetingObj, "dialogVisible", $event)
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c("span", [_vm._v(_vm._s(_vm.parkmeetingObj.dialogTitle))]),
          ]),
          _vm.parkmeetingObj.dialogVisible
            ? _c("meetingDialog", {
                attrs: {
                  dialogStatus: _vm.parkmeetingObj.dialogStatus,
                  id: _vm.parkmeetingObj.id,
                  suggestionObj: _vm.suggestionObj,
                },
                on: { close: _vm.handleClose },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }